import React from "react"
import logo from "../../static/logo_text_medium.png"
import logoHi from "../../static/logo_text_medium_hi.png"
import { Link } from "gatsby"
import "./navbar.css"
import { useState } from "react"
import Dict from "../langs/dict"

if (typeof window !== "undefined") {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export default props => {
  const [open, setOpen] = useState(false)
  const [nav, setNav] = useState(false)
  const dict = new Dict(props.lang)

  const langs = ["en", "fr"]
  let langUrl = (lang, url) => {
    return (lang == "en" ? "" : lang) + url
  }

  return (
    <nav id="navbar" className={nav ? "openNav" : ""}>
      <div id="logo-menu-holder">
        <Link to={dict.getTrans("navbar_link_home")}>
          <img
            id="navbar-logo"
            alt="logo"
            src={logo}
            onMouseOver={e => (e.currentTarget.src = logoHi)}
            onMouseOut={e => (e.currentTarget.src = logo)}
          />
        </Link>
        <div id="mobile-nav">
          <button onClick={() => setNav(!nav)}>{nav ? "×" : "☰"}</button>
        </div>
      </div>
      <ul id="menu-items">
        <Link to={dict.getTrans("navbar_link1")}>
          <li className="menu-item">{dict.getTrans("navbar_1")}</li>
        </Link>
        <Link to={dict.getTrans("navbar_link2")}>
          <li className="menu-item">{dict.getTrans("navbar_2")}</li>
        </Link>
        <Link to={dict.getTrans("navbar_link3")}>
          <li className="menu-item">{dict.getTrans("navbar_3")}</li>
        </Link>
        <a href="#contact-holder">
          <li id="button-holder">
            <div className="contact-us">{dict.getTrans("navbar_cta")}</div>
          </li>
        </a>
        <li id="language-switcher">
          <ul id="lang-list">
            <li
              id="selected-lang"
              className="lang-selector"
              onClick={() => setOpen(!open)}
            >
              {props.lang.toUpperCase()}
              <span id="down-arrow">⌄</span>
            </li>
            <Link
              to={langUrl(
                langs.filter(x => (x == props.lang ? null : x))[0],
                props.pageUrl
              )}
            >
              <li className="lang-selector" className={open ? "" : "hidden"}>
                {langs
                  .filter(x => (x == props.lang ? null : x))[0]
                  .toUpperCase()}
              </li>
            </Link>
          </ul>
        </li>
      </ul>
    </nav>
  )
}
