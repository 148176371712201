import React from "react"
import "./services.css"
import Dict from "../langs/dict"
import WebIcon from "../../static/icons/web_min.png"
import MobileIcon from "../../static/icons/mobile_min.png"
import StrategyIcon from "../../static/icons/strategy_min.png"
import BrandingIcon from "../../static/icons/branding_min.png"
import LeftSectionTitle from "../components/leftSectionTitle"

export default props => {
  const dict = new Dict(props.lang)
  return (
    <div id="services-holder">
      <LeftSectionTitle
        title={dict.getTrans("services_title")}
        subTitle={dict.getTrans("services_text")}
      />
      <div id="services-list">
        <div className="service-col">
          <img src={WebIcon} alt="web-icon" className="service-icon" />
          <h3 className="service-title">
            <span className=" green-hl">
              {dict.getTrans("services_web_title")}
            </span>
          </h3>
          <ul className="service-list">
            <li>{dict.getTrans("services_web_1")}</li>
            <li>{dict.getTrans("services_web_2")}</li>
            <li>{dict.getTrans("services_web_3")}</li>
            <li>{dict.getTrans("services_web_4")}</li>
            <li>{dict.getTrans("services_web_5")}</li>
            <li>{dict.getTrans("services_web_6")}</li>
          </ul>
        </div>
        <div className="service-col">
          <img src={MobileIcon} alt="mobile-icon" className="service-icon" />
          <h3 className="service-title">
            <span className=" pink-hl">
              {dict.getTrans("services_mobile_title")}
            </span>
          </h3>
          <ul className="service-list">
            <li>{dict.getTrans("services_mobile_1")}</li>
            <li>{dict.getTrans("services_mobile_2")}</li>
            <li>{dict.getTrans("services_mobile_3")}</li>
            <li>{dict.getTrans("services_mobile_4")}</li>
            <li>{dict.getTrans("services_mobile_5")}</li>
          </ul>
        </div>
        <div className="service-col">
          <img
            src={StrategyIcon}
            className="service-icon"
            alt="strategy-icon"
          />
          <h3 className="service-title">
            <span className=" orange-hl">
              {dict.getTrans("services_digital_title")}
            </span>
          </h3>
          <ul className="service-list">
            <li>{dict.getTrans("services_digital_1")}</li>
            <li>{dict.getTrans("services_digital_2")}</li>
            <li>{dict.getTrans("services_digital_3")}</li>
            <li>{dict.getTrans("services_digital_4")}</li>
            <li>{dict.getTrans("services_digital_5")}</li>
          </ul>
        </div>
        <div className="service-col">
          <img
            src={BrandingIcon}
            alt="branding-icon"
            className="service-icon"
          />
          <h3 className="service-title">
            <span className=" blue-hl">
              {dict.getTrans("services_brand_title")}
            </span>
          </h3>
          <ul className="service-list">
            <li>{dict.getTrans("services_brand_1")}</li>
            <li>{dict.getTrans("services_brand_2")}</li>
            <li>{dict.getTrans("services_brand_3")}</li>
            <li>{dict.getTrans("services_brand_4")}</li>
            <li>{dict.getTrans("services_brand_5")}</li>
            <li>{dict.getTrans("services_brand_6")}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
