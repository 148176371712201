import React from "react"
import "./layout.css"
import Navbar from "./navbar"
import Footer from "./footer"
import SEO from "../components/seo"

export default props => {
  return (
    <div>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        lang={props.seoLang}
      />
      <Navbar lang={props.lang} pageUrl={props.pageUrl} />
      {props.children}
      <Footer lang={props.lang} />
    </div>
  )
}
